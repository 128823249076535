// src/App.js
import React, { useRef } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { UserProvider } from './UserContext'; // Import the UserProvider
import './App.css';
import Login from './LoginForm';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
} from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import HQLogo from './components/HQLogo'; // Ensure this is correctly imported

const AppLayout = ({ children, taskComponentRef }) => {
  const handleRefresh = () => {
    if (taskComponentRef.current) {
      taskComponentRef.current.refreshData();
    }
  };

  return (
    <div className='flex flex-col min-h-screen w-screen overflow-hidden'>
      <Navbar className='py-4'>
        <NavbarBrand className='hidden lg:flex'>
          <HQLogo />
        </NavbarBrand>
        <NavbarContent className='sm:flex gap-4' justify='center'>
          <NavbarItem>
            <span className='text-7xl font-semibold animated-gradient'>
              COA TOOL
            </span>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent justify='end'>
          <NavbarItem className='hidden lg:flex'>
            <Button color='primary' onPress={handleRefresh}>
              REFRESH
            </Button>
          </NavbarItem>
          <NavbarItem className='hidden lg:flex'>
            <Button
              color='success'
              onPress={() => taskComponentRef.current.handleUploadOpen()}
            >
              UPLOAD
            </Button>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      <main className='flex-grow'>{children}</main>
      <footer
        className='w-full flex items-center justify-center'
        style={{ paddingBottom: '0px' }}
      >
        <Link
          isExternal
          className='flex items-center gap-2 text-current'
          href='https://www.tryheadquarters.com/'
          title='tryheadquarters.com homepage'
        >
          <span className='text-default-600'>Powered by</span>
          <p className='text-primary'>HEADQUARTERS LLC</p>
        </Link>
      </footer>
    </div>
  );
};

const App = () => {
  const taskComponentRef = useRef(null);

  return (
    <UserProvider>
      {' '}
      {/* Wrap the entire app in UserProvider */}
      <Router>
        <AppRoutes taskComponentRef={taskComponentRef} />
      </Router>
    </UserProvider>
  );
};

const AppRoutes = ({ taskComponentRef }) => {
  const location = useLocation();

  const isAuthRoute = location.pathname === '/';

  return (
    <div className='flex-col min-h-screen'>
      {isAuthRoute ? (
        <Routes>
          <Route path='/' element={<Login />} />
        </Routes>
      ) : (
        <AppLayout taskComponentRef={taskComponentRef}>
          <Routes>
            <Route
              path='/dashboard'
              element={
                <ProtectedRoute
                  element={<Dashboard ref={taskComponentRef} />}
                />
              }
            />
          </Routes>
        </AppLayout>
      )}
    </div>
  );
};

export default App;
