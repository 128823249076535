import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NextUIProvider>
      <React.Suspense fallback='loading'>
        <main className='w-screen h-screen overflow-auto flex items-start justify-center'>
          <App />
        </main>
      </React.Suspense>
    </NextUIProvider>
  </React.StrictMode>
);

reportWebVitals();
