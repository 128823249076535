// src/LoginForm.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Input,
  Spacer,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarContent,
  Avatar,
  AvatarGroup,
} from '@nextui-org/react';

import EyeFilledIcon from './components/EyeFilledIcon';
import EyeSlashFilledIcon from './components/EyeSplashFilledIcon';

import myLogo from './logo_test_2.png'; // Adjust the path to where your image is stored
import stefano from './stefano_icon.jpeg';
import ireni from './ireni_icon.jpeg';
import emily from './emily_icon.jpeg';
import dusan from './dusan_icon.jpeg';
import ernesto from './ernesto_icon.jpeg';
import Logo420 from './components/Logo420';
import MailIcon from './MailIcon';

import PasswordIcon from './PasswordIcon';
import HQLogo from './components/HQLogo'; // Ensure this is correctly imported
import { UserContext } from './UserContext'; // Import UserContext

const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext); // Get setUser from context

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Store the token
        setUser({ username }); // Store the username in context
        localStorage.setItem('username', username); // Store the username in localStorage

        setUsername(''); // Clear the username
        setPassword(''); // Clear the password
        navigate('/dashboard'); // Redirect to the dashboard
      } else {
        alert('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className='w-screen h-screen flex-col min-h-screen items-center justify-center overflow-hidden'>
      <div className='flex flex-col lg:flex-row w-full h-full'>
        <div className='text-white flex flex-col justify-between w-full lg:w-1/2 p-10 bg-gradient-to-br from-sky-900 to-indigo-900'>
          <div className='flex flex-col justify-center flex-grow'>
            <h1 className='text-5xl font-bold'>Sign In to</h1>
            <h2 className='text-4xl mt-4 font-semibold'>420 Feed</h2>
            <p className='text-2xl mt-4 bg-custom-gradient bg-clip-text text-transparent font-bold'>
              Catalogue management just got easy!
            </p>
            <div className='flex items-center space-x-2 mt-6'>
              <AvatarGroup isBordered>
                <Avatar src={ireni} />
                <Avatar src={emily} />
                <Avatar src={stefano} />
                <Avatar src={dusan} />
                <Avatar src={ernesto} />
              </AvatarGroup>
              <p className='ml-2 text-1xl font-bold'>Join the team.</p>
            </div>
          </div>
          <span className='text-sm mt-10 flex items-center'>
            Made with <Spacer x={2} /> ❤️ <Spacer x={2} /> by <Spacer x={3} />{' '}
            <HQLogo />
          </span>
        </div>

        <div className='flex flex-col justify-between w-full lg:w-1/2 p-10'>
          <div className='w-full flex items-center justify-center '>
            <div className='inline-flex items-center'>
              <img src={myLogo} alt='Logo' className='w-32 h-auto' />
            </div>
          </div>
          <div className='flex flex-col justify-center items-center flex-grow'>
            <div className='w-full max-w-md'>
              <h1 className='text-3xl mb-4 text-left font-medium'>
                Hi, Welcome Back! 👋🏼
              </h1>
              <Card className='w-full p-8'>
                <form className='space-y-10' onSubmit={handleLogin}>
                  <Input
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Enter your username'
                    label='Username'
                    labelPlacement='outside'
                    css={{ label: { color: '#3182ce' } }} // Assuming `css` prop can target label
                    startContent={
                      <MailIcon className='text-2xl text-default-400 pointer-events-none flex-shrink-0' />
                    }
                  />
                  <Spacer y={5} />
                  <Input
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Enter your password'
                    className='text-white'
                    type={isVisible ? 'text' : 'password'}
                    label='Password'
                    labelPlacement='outside'
                    startContent={
                      <PasswordIcon className='text-2xl text-default-400 pointer-events-none flex-shrink-0' />
                    }
                    endContent={
                      <button
                        className='focus:outline-none'
                        type='button'
                        onClick={toggleVisibility}
                      >
                        {isVisible ? (
                          <EyeSlashFilledIcon className='text-2xl text-default-400 pointer-events-none' />
                        ) : (
                          <EyeFilledIcon className='text-2xl text-default-400 pointer-events-none' />
                        )}
                      </button>
                    }
                  />
                  <Button
                    fullWidth
                    color='primary'
                    className='bg-blue-600'
                    type='submit'
                  >
                    SIGN IN
                  </Button>
                </form>
              </Card>
            </div>
          </div>
          <div className='h-16'></div> {/* Spacer to push the form up */}
        </div>
      </div>
    </div>
  );
};

export default Login;
