import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40px' // Reduced width
      height='24px' // Reduced height
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipay: 'evenodd',
      }}
      version='1.1'
      viewBox='0 0 158 95'
      xmlSpace='preserve'
    >
      <path
        d='M98 1h61c0 25.687 0 51.375-.352 77.782-.782-.113-1.383-.9-1.615-1.783-2.102-7.994-4.14-16.005-6.134-24.423-1.31-6.92-2.127-13.612-4.186-19.896-3.098-9.45-9.422-13.147-18.713-13.718-3.552-.219-4.63 1.708-3.927 4.599 2.48 10.191 5.188 20.328 7.85 30.897.927 4.198 1.721 8.008 2.721 11.764 1.562 5.864-1.07 10.045-7.515 9.927-21.589-.396-43.19-.15-64.786-.15-2.492 0-4.989-.094-7.475.025-3.162.15-5.136.834-4.05 5.062 1.596 6.205 5.072 10.352 10.76 12.962.638.293.956 1.285 1.422 1.952H1c0-29.02 0-58.042.239-87.866 2.375 1.102 4.511 3.006 6.803 5.247 3.46 1.432 6.736 3.359 10.074 3.47 11.595.391 23.21.134 34.818.159 6.191.013 8.792 2.612 8.551 8.929-17.142.106-33.82.166-50.757.191-.36.26-.46.554-.636 1.234.62 2.333 1.098 4.393 2.047 6.206 4.043 7.73 11.49 9.105 19.075 9.338 11.14.342 22.309.356 33.446-.026 5.529-.19 8.208 2.242 9.27 7.485-1.896.46-3.551.618-5.207.62-15.154.02-30.308.052-45.461-.016-3.924-.017-5.094 1.117-3.327 5.033 3.708 8.217 9.702 12.339 18.85 12.04 7.152-.234 14.319-.047 21.48-.047 18.65 0 37.301.02 55.952-.013 5.929-.01 8.33-2.285 7.68-8.034-.46-4.073-1.865-8.04-2.873-12.464-1.653-7.116-3.28-13.822-4.96-20.93-1.684-4.8-3.316-9.198-5.083-13.905-4.157-3.448-8.202-6.557-12.174-9.757-.472-.38-.548-1.25-.807-1.894z'
        opacity='1'
      ></path>
      <path
        fill='#DF682C'
        d='M150.837 52.988c2.057 8.006 4.094 16.017 6.196 24.01.232.884.833 1.671 1.615 2.252C159 84.645 159 90.29 159 96H63.469c-.935-.667-1.253-1.659-1.891-1.952-5.688-2.61-9.164-6.757-10.76-12.962-1.086-4.228.888-4.911 4.05-5.062 2.486-.119 4.983-.026 7.475-.026 21.596 0 43.197-.245 64.786.15 6.445.119 9.077-4.062 7.515-9.926-1-3.756-1.794-7.566-2.33-11.84 6.407-.79 12.465-1.092 18.523-1.394z'
        opacity='1'
      ></path>
      <path
        fill='#F6C744'
        d='M7.886 13.044C5.75 11.14 3.614 9.236 1.24 7.666 1 5.676 1 3.353 1 1h96.531c.728.643.804 1.515 1.276 1.894 3.972 3.2 8.017 6.31 11.758 9.84-8.516.684-16.748 1.165-24.984 1.226-17.451.13-34.905.14-52.357-.003-8.448-.07-16.892-.594-25.338-.913z'
        opacity='1'
      ></path>
      <path
        fill='#F4BC41'
        d='M10.986 26.166c16.679-.06 33.357-.121 50.835.025 1.266.368 1.731.662 2.202.671 11.27.216 22.54.49 33.811.556 6.093.036 12.187-.294 18.281-.46 1.628 6.706 3.256 13.412 4.68 20.852-2.223 1.22-4.237 2.104-6.26 2.122-13.454.124-26.91.062-40.365.06-1.302-4.868-3.981-7.3-9.51-7.11-11.137.382-22.305.368-33.446.026-7.586-.233-15.032-1.609-19.075-9.338-.95-1.813-1.426-3.873-1.89-6.32.426-.671.596-.866.737-1.084z'
        opacity='1'
      ></path>
      <path
        fill='#F3B13E'
        d='M73.93 50.367c13.696-.373 27.151-.311 40.606-.435 2.022-.018 4.036-.903 6.283-1.711 1.213 3.69 2.619 7.656 3.079 11.729.649 5.749-1.752 8.024-7.68 8.034-18.652.033-37.303.012-55.954.013-7.16 0-14.327-.187-21.48.047-9.147.299-15.14-3.823-18.849-12.04-1.767-3.916-.597-5.05 3.327-5.033 15.153.068 30.307.035 45.461.016a21.542 21.542 0 005.207-.62z'
        opacity='1'
      ></path>
      <path
        fill='#F5C143'
        d='M116.063 26.555c-6.042.569-12.136.899-18.229.863-11.271-.066-22.541-.34-33.811-.556-.47-.009-.936-.303-1.739-.716-.558-6.524-3.159-9.123-9.35-9.136-11.608-.025-23.223.232-34.818-.158-3.338-.112-6.614-2.04-10.074-3.471 8.29-.018 16.734.507 25.182.576 17.452.143 34.906.133 52.357.003 8.236-.06 16.468-.542 25.119-.915 2.048 4.313 3.68 8.71 5.363 13.51z'
        opacity='1'
      ></path>
      <path
        fill='#EA7737'
        d='M150.9 52.576c-6.12.714-12.18 1.016-18.628 1.394-3.011-10.081-5.719-20.218-8.199-30.41-.703-2.89.375-4.817 3.927-4.598 9.291.571 15.615 4.268 18.713 13.718 2.06 6.284 2.875 12.976 4.186 19.896z'
        opacity='1'
      ></path>
      <path
        fill='#F5C143'
        d='M10.728 26.13c.117.254-.053.449-.406.733-.055-.18.046-.474.406-.733z'
        opacity='1'
      ></path>
    </svg>
  );
}

export default Icon;
