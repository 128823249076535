import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardBody,
  Chip,
  Spinner,
  useDisclosure,
  getKeyValue,
  Input,
} from '@nextui-org/react';
import BatchModal from './BatchModal';
import UploadModal from './UploadModal';

const apiUrl = process.env.REACT_APP_API_URL;

const columns = [
  { key: 'batch_no', label: 'BATCH NO', allowsSorting: true },
  { key: 'date_start', label: 'DATE START' },
  { key: 'date_end', label: 'DATE END' },
  { key: 'account', label: 'ACCOUNT' },
  { key: 'not_done', label: 'NOT DONE' },
  { key: 'done', label: 'DONE' },
  { key: 'remaining', label: 'REMAINING' },
  { key: 'total', label: 'TOTAL' },
  { key: 'downloaded', label: 'DOWNLOADED' },
  { key: 'status', label: 'STATUS' },
];

const statusColorMap = {
  active: 'warning',
  finished: 'success',
  failed: 'danger',
};

const Dashboard = forwardRef((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const [selectionBehavior, setSelectionBehavior] = useState('toggle');
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    fetchBatches();
  }, []);

  useImperativeHandle(ref, () => ({
    refreshData() {
      setBatches([]); // Clear the table data first
      fetchBatches();
    },
    handleUploadOpen() {
      onUploadOpen();
    },
  }));

  const fetchBatches = async () => {
    if (isLoading) {
      // Abort the previous request
      if (abortController) {
        abortController.abort();
      }
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    setAbortController(controller);

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('username');
      const response = await fetch(`${apiUrl}/api/get_batches`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user: user }),
        signal: controller.signal,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Incoming Data:', data);
        // const processedData = data.batches.map((batch) => {
        //   let status;

        //   if (batch.done === batch.total) {
        //     status = 'finished';
        //   } else if (batch.total === batch.not_done) {
        //     status = 'failed';
        //   } else {
        //     status = 'active';
        //   }
        //   return { ...batch, status };
        // });

        setBatches(data.batches);
      } else {
        console.error('Error fetching batches:', response.statusText);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching batches:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOpen = async (key) => {
    setSelectedBatch([]);
    try {
      console.log('Key: ', key);
      console.log('Batches: ', batches);

      // Find the batch with the matching key
      const batch = batches.find((batch) => batch.key === key);

      // Check if the batch was found
      if (!batch) {
        console.error('Batch not found for key:', key);
        return;
      }

      // Extract the batch_no from the found batch
      const batch_no = batch.batch_no;
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/api/get_batch_results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ batch_no: batch.batch_no }), // Send the key as the id
      });

      if (response.ok) {
        const data = await response.json();
        setSelectedBatch(data.results);
        onOpen();
      } else {
        console.error('Error fetching batch results:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching batch results:', error);
    }
  };

  const renderCell = (item, columnKey) => {
    const cellValue = getKeyValue(item, columnKey);

    if (columnKey === 'status') {
      return (
        <Chip
          className='capitalize'
          color={statusColorMap[item.status]}
          size='sm'
          variant='flat'
        >
          {cellValue}
        </Chip>
      );
    }

    return cellValue;
  };

  return (
    <div className='flex flex-col'>
      <main className='flex-grow py-4'>
        <div className='container mx-auto h-full flex items-center justify-center'>
          <Card className='w-full h-full lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl'>
            <CardBody className='overflow-hidden h-full'>
              <h1>Your previous batches</h1>
              <div className='overflow-auto h-full'>
                <Table
                  color='success'
                  selectionMode='single'
                  selectionBehavior={selectionBehavior}
                  onRowAction={(key) => handleModalOpen(key)}
                  classNames={{
                    base: 'sm:max-h-[320px] md:max-h-[400px] lg:max-h-[480px] xl:max-h-[560px] overflow',
                    table:
                      'sm:min-h-[300px] md:min-h-[350px] lg:min-h-[400px] xl:min-h-[450px] min-w-[1200px]',
                  }}
                >
                  <TableHeader columns={columns}>
                    {(column) => (
                      <TableColumn key={column.key}>{column.label}</TableColumn>
                    )}
                  </TableHeader>
                  <TableBody
                    items={batches}
                    isLoading={isLoading}
                    loadingContent={<Spinner label='Loading...' />}
                  >
                    {(item) => (
                      <TableRow key={item.id}>
                        {(columnKey) => (
                          <TableCell>{renderCell(item, columnKey)}</TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </CardBody>
            <BatchModal
              isOpen={isOpen}
              onClose={onClose}
              data={selectedBatch}
              selectionBehavior={selectionBehavior}
            />
            <UploadModal
              isOpen={isUploadOpen}
              onClose={onUploadClose}
              refreshData={fetchBatches}
            />
          </Card>
        </div>
      </main>
    </div>
  );
});

export default Dashboard;
