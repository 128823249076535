import React from 'react';

const PasswordIcon = (props) => (
  <svg
    aria-hidden='true'
    fill='none'
    focusable='false'
    height='1em'
    role='presentation'
    viewBox='0 0 24 24'
    width='1em'
    {...props}
  >
    <g transform='translate(2 1.999)'>
      <path
        d='M5.666,20C2.277,20,0,17.623,0,14.084V5.917C0,2.378,2.277,0,5.666,0h8.669C17.724,0,20,2.378,20,5.917v8.168C20,17.623,17.723,20,14.334,20Zm7.266-9.25h1.329v1.1a.75.75,0,0,0,1.5,0V10a.751.751,0,0,0-.75-.75H9.319a2.6,2.6,0,1,0,0,1.5h2.112v1.1a.75.75,0,0,0,1.5,0v-1.1h0ZM5.739,10a1.1,1.1,0,1,1,1.1,1.1A1.1,1.1,0,0,1,5.739,10Z'
        fill='currentColor'
      ></path>
    </g>
  </svg>
);

export default PasswordIcon;
