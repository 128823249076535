import React from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
} from '@nextui-org/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL;

const UploadModal = ({ isOpen, onClose, refreshData }) => {
  const [links, setLinks] = React.useState('');
  const toastId = React.useRef(null);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');

      // Extract URLs using regex, excluding trailing punctuation marks like )
      const urlRegex = /(https?:\/\/[^\s\)]+)/g;
      const linksArray = links.match(urlRegex);

      // Filter out any invalid entries (optional, since the regex should only capture valid URLs)
      const filteredLinks = (linksArray || []).filter(
        (link) => link.trim() !== ''
      );

      // Show loading toast
      const toastId = toast.loading(
        'Waiting for server to download all the files, please wait...',
        {
          position: 'bottom-center',
          theme: 'dark',
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        }
      );

      // Send the array of URLs to the server
      const response = await fetch(`${apiUrl}/api/process`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ links: filteredLinks }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Result:', result);
        console.log('Task ID:', result.task_id);
        toast.update(toastId, {
          position: 'bottom-center',
          render:
            'Files successfully downloaded. Click refresh 🔄 button and wait for processing to finish!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
        onClose();
        refreshData(); // Refresh the table data after submission
      } else {
        console.error('Error:', response.statusText);
        toast.update(toastId, {
          position: 'bottom-center',
          render: `Error: ${response.statusText}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.update(toastId, {
        position: 'bottom-center',
        render: `Error: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
    }
  };

  return (
    <>
      <Modal
        backdrop='blur'
        size='5xl'
        isOpen={isOpen}
        onClose={onClose}
        className='dark text-foreground'
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Upload links
              </ModalHeader>
              <ModalBody>
                <Textarea
                  placeholder='Paste links from airtable here...'
                  className='w-full'
                  value={links}
                  onChange={(e) => setLinks(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Close
                </Button>
                <Button onPress={handleSubmit} color='primary'>
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UploadModal;
