import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  getKeyValue,
  Link,
} from '@nextui-org/react';
import Papa from 'papaparse';

const tableStyles = {
  sm: {
    maxHeight: '320px',
    overflow: 'auto',
  },
  md: {
    maxHeight: '400px',
    overflow: 'auto',
  },
  lg: {
    maxHeight: '480px',
    overflow: 'auto',
  },
  xl: {
    maxHeight: '80vh',
    overflow: 'auto',
  },
};

const BatchModal = ({ isOpen, onClose, data, selectionBehavior }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      // Simulate data fetching
      setTimeout(() => {
        setIsLoading(false);
      }, 1000); // Replace this with actual data fetching logic
    }
  }, [isOpen]);

  const columns = [
    { key: 'name', label: 'NAME' },
    { key: 'strain', label: 'STRAIN' },
    { key: 'matrix', label: 'MATRIX' },
    { key: 'cbd_total', label: 'CBD TOTAL' },
    { key: 'thc_total', label: 'THC TOTAL' },
    { key: 'total_cannabinoids', label: 'TOTAL CANNABINOIDS' },
    { key: 'thca_total', label: 'THCA TOTAL' },
    { key: 'batch', label: 'BATCH' },
    { key: 'top_terpenes', label: 'TOP TERPENES' },
    { key: 'url', label: 'URL' },
    { key: 'file_name', label: 'FILE NAME' },
    { key: 'original_url', label: 'ORIGINAL URL' },
  ];

  // Ensure the data is in the correct order
  const orderedData = data.map((item) => {
    const orderedItem = {};
    columns.forEach((col) => {
      orderedItem[col.key] = item[col.key];
    });
    return orderedItem;
  });

  const handleDownload = () => {
    const csv = Papa.unparse(orderedData, {
      header: true,
      columns: columns.map((col) => col.key),
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    // Get the current date and time
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const fileName = `coa_batch_${year}-${month}-${day}_${hours}:${minutes}.csv`;

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onClose();
  };

  return (
    <Modal
      backdrop='blur'
      size='5xl'
      isOpen={isOpen}
      onClose={onClose}
      className='dark text-foreground'
    >
      <ModalContent className='w-full max-w-7xl'>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              Batch Details
            </ModalHeader>
            <ModalBody style={tableStyles}>
              {isLoading ? (
                <div className='flex justify-center items-center h-full'>
                  <Spinner label='Loading...' />
                </div>
              ) : (
                <Table
                  isHeaderSticky
                  aria-label='Batch results table'
                  selectionMode='single'
                  selectionBehavior={selectionBehavior}
                  classNames={{
                    base: 'max-h-[520px] overflow-scroll',
                    table: 'min-h-[420px]',
                  }}
                >
                  <TableHeader columns={columns}>
                    {(column) => (
                      <TableColumn key={column.key}>{column.label}</TableColumn>
                    )}
                  </TableHeader>
                  <TableBody items={data}>
                    {(item) => (
                      <TableRow key={item.id}>
                        {(columnKey) => (
                          <TableCell>
                            {columnKey === 'url' ||
                            columnKey === 'original_url' ? (
                              <Button
                                href={getKeyValue(item, columnKey)}
                                as={Link}
                                target='_blank'
                                showAnchorIcon
                                color='success'
                                underline
                              >
                                {getKeyValue(item, columnKey)}
                              </Button>
                            ) : (
                              getKeyValue(item, columnKey)
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='light' onPress={onClose}>
                Close
              </Button>
              <Button color='primary' onPress={handleDownload}>
                Download .csv
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BatchModal;
